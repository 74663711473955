import SectionHeading from "../../../../components/shared/Sectionheading"
import React from "react"
import { Card, Col, Container, Form, Image, Row } from "react-bootstrap"
import Paragraph from "../../../../components/shared/Paragraph"
import * as styles from "./styles.module.scss"
import profile from "../../../../assets/images/profile_img.png"
import Buttons from "../../../../components/shared/Button"

const AdvocatesForm = () => {
	const [email, setEmail] = React.useState("")
	const [region, setRegion] = React.useState("")

	return (
		<Container>
			<Row>
				<Col md={12}>
					<SectionHeading className="mt-5">ADVOCATE Profile</SectionHeading>
				</Col>
			</Row>
			<Form className={styles.formAdvocate}>
				<Row>
					<Col md={4} className="mt-5">
						<Paragraph className="fw-bold">Personal information</Paragraph>
						{/* <Paragraph>
							Galea offers program-wide support through mental health literacy and advocacy programs for athletes,
							parents, and coaches
						</Paragraph> */}
					</Col>
					<Col md={8} className="mt-md-5 mt-4">
						<Card>
							<Card.Body>
								<Row>
								<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>First Name</Form.Label>
											<Form.Control type="text" placeholder="John*" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>Last Name</Form.Label>
											<Form.Control type="text" placeholder="Doe*" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>Email</Form.Label>
											<Form.Control type="email" placeholder="deanna.curtis@example.com*" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>Confirm Email </Form.Label>
											<Form.Control type="email" placeholder="deanna.curtis@example.com*" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>Password </Form.Label>
											<Form.Control type="password" placeholder="deanna.curtis@example.com*" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>Confirm Password </Form.Label>
											<Form.Control type="password" placeholder="deanna.curtis@example.com*" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>Phone </Form.Label>
											<Form.Control type="text" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" controlId="formBasicName">
											<Form.Label>Country/Region</Form.Label>
											<Form.Control type="text" placeholder="Réunion*" />
										</Form.Group>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="mb-5 pb-5">
					<Col md={4} className="mt-5">
						<Paragraph className="fw-bold">Profile details</Paragraph>
						{/* <Paragraph>
							Galea offers program-wide support through mental health literacy and advocacy programs for athletes,
							parents, and coaches
						</Paragraph> */}
					</Col>
					<Col md={8} className="mt-md-5 mt-4">
						<Card>
							<Card.Body>
								<Row>
									<Col md={12} className=" mt-4">
										<Form.Label>Profile Image</Form.Label>
										<div>
											<Image src={profile} fluid />
										</div>
									</Col>
									<Col md={12} className="mt-md-5 mt-4">
										<Form.Label>Add more Images</Form.Label>
									</Col>
									<Col md={2} sm={6} xs={6} className="mt-md-0 mt-4">
										<div>
											<Image src={profile} fluid />
										</div>
									</Col>
									<Col md={2} sm={6} xs={6} className="mt-md-0 mt-4">
										<div>
											<Image src={profile} fluid />
										</div>
									</Col>
									<Col md={2} sm={6} xs={6} className="mt-md-0 mt-4">
										<div>
											<Image src={profile} fluid />
										</div>
									</Col>
									<Col md={2} sm={6} xs={6} className="mt-md-0 mt-4">
										<div>
											<Image src={profile} fluid />
										</div>
									</Col>
								</Row>
								<Row className="mt-md-5 mt-4">
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>First Name</Form.Label>
											<Form.Control type="text" placeholder="Leslie Alexander" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>Last Name</Form.Label>
											<Form.Control type="text" placeholder="Dianne Russell" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>Advocate Level</Form.Label>
											<Form.Control type="text" placeholder="Pro Advocate" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>Favorite Sport</Form.Label>
											<Form.Control type="text" placeholder="Volley Ball" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>Education</Form.Label>
											<Form.Control type="text" placeholder="College" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>Website</Form.Label>
											<Form.Control type="text" placeholder="http://www.statholdings.com" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<Paragraph className="fw-bold mt-md-5 mt-4">Social Media Links </Paragraph>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>Instgram</Form.Label>
											<Form.Control type="text" placeholder="http://www.statholdings.com" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>LinkdIn</Form.Label>
											<Form.Control type="text" placeholder="http://www.statholdings.com" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-4" controlId="formBasicName">
											<Form.Label>Facebook</Form.Label>
											<Form.Control type="text" placeholder="http://www.statholdings.com" />
										</Form.Group>
									</Col>
									<Col md={12} className="mt-5">
										<Form.Label>Why I want to be an advocate</Form.Label>

										<Form.Control
											as="textarea"
											className="mb-3"
											placeholder="Write here..."
											style={{ height: "140px" }}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<div className="text-end mb-3">
											<Buttons> Save</Buttons>
										</div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		</Container>
	)
}

export default AdvocatesForm
