import React from "react"
import { Main } from "../components/layout"
import AdvocatesForm from "../components/pages/advocate/AdvocateForm"

const AdvocateForm = () => {
	return (
		<Main>
			<AdvocatesForm />
		</Main>
	)
}

export default AdvocateForm
